function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import FAQ from './components/FAQ';
import { formatNumber } from '@/utils/tool';
import { getPaymentInfo } from '@/api/payment';
import { COMBO_TYPE } from '@/utils/common';
import PaymentDialog from './components/PaymentDialog.vue';
import PaymentStatusDialog from './components/PaymentStatusDialog.vue';
import { mapState } from 'vuex';
export default {
  components: {
    FAQ: FAQ,
    PaymentDialog: PaymentDialog,
    PaymentStatusDialog: PaymentStatusDialog
  },
  data: function data() {
    return {
      hours: 1,
      fontSize: 'inline',
      loading: false,
      COMBO_TYPE: COMBO_TYPE,
      reqTimer: null,
      payInfo: {
        level: 1,
        gift_store: '0',
        // 赠送存储
        act_price: 0,
        // 实际价格
        charge_unit: 18,
        // 单价
        gift_account_amount: 0,
        // 赠送账号数
        gift_price: 0,
        // 优惠价
        gift_ratio: 0,
        // 赠送比例
        gift_gpu: 0 // 赠送GPU时长
      }
    };
  },

  filters: {
    formatNumber: formatNumber
  },
  computed: _objectSpread(_objectSpread({}, mapState('user', ['companyInfo'])), {}, {
    imgNum: function imgNum() {
      var num = this.hours * 960;
      return num;
    }
  }),
  created: function created() {
    this.payInfo.level = this.companyInfo.level || 1;
  },
  mounted: function mounted() {
    this.isShowPaymentStatusDialog();
    this.changeHours();
  },
  methods: {
    viewFAQ: function viewFAQ() {
      this.$refs.FAQ.showDialog();
    },
    changeHours: function changeHours() {
      var _this = this;
      this.hours = this.hours.toString().replace(/^(0+)|[^\d]+/g, '');
      this.loading = true;
      if (this.reqTimer) {
        clearTimeout(this.reqTimer);
        this.reqTimer = null;
      }
      this.reqTimer = setTimeout(function () {
        var quantity = _this.hours;
        if (quantity < 1 || !quantity) {
          quantity = 1;
          _this.hours = 1;
        }
        _this.calculatePrice(quantity);
      }, 500);
    },
    calculatePrice: function calculatePrice(quantity) {
      var _this2 = this;
      getPaymentInfo({
        quantity: parseInt(quantity),
        currency: this.$CHINA ? '' : 'usd'
      }).then(function (res) {
        _this2.payInfo = res.data;
        _this2.loading = false;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    changeNum: function changeNum(num) {
      if (parseInt(this.hours) + num < 1) {
        return;
      }
      this.hours = parseInt(this.hours) + num;
      this.changeHours();
    },
    payment: function payment() {
      var hours = this.hours,
        _this$payInfo = this.payInfo,
        act_price = _this$payInfo.act_price,
        level = _this$payInfo.level,
        charge_unit = _this$payInfo.charge_unit;
      var level_txt = this.$t(COMBO_TYPE[level - 1]);
      var type = this.$t(this.$CHINA ? 'Renewal16' : 'Renewal166', {
        level: level_txt,
        num: charge_unit
      });
      this.$refs.paymentDialog.showDialog({
        price: act_price,
        numbers: parseInt(hours)
      }, type);
    },
    // 支付后展示状态的弹窗
    isShowPaymentStatusDialog: function isShowPaymentStatusDialog() {
      var urlParams = this.$route.query;
      // 这三种id只会有一种 分别对应三种支付方式回调返回的id
      var orderId = urlParams.orderId,
        token = urlParams.token,
        out_trade_no = urlParams.out_trade_no;
      var suviver = orderId || token || out_trade_no;
      // 1.payermax 2.google 3.apple 4.支付宝 5.微信 6.paypal 7.unionpay
      var third_type = 0;
      if (orderId) {
        third_type = 1;
      }
      if (out_trade_no) {
        third_type = 4;
      }
      if (token) {
        third_type = 6;
      }
      // 如果third_type为0 说明是普通打开 不是第三方支付页面重定向回来的 所以不弹出这个弹窗
      if (third_type) {
        var data = {
          third_type: third_type,
          order_no: suviver
        };
        this.showSueecssDialog(data);
      }
    },
    showSueecssDialog: function showSueecssDialog(data) {
      this.$refs.payStatusDialog.showDialog(data);
    }
  }
};