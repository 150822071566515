export default {
  EnterpriseEdition: '企业版',
  Home: '首页',
  AcconutManage: '账号管理',
  Account: '账号',
  Password: '密码',
  Analysis: '数据分析',
  APIInfo: 'API信息',
  RenewalPackage: '续费套餐',
  Purchase: '购买记录',
  PurchaseHistory: '购买历史',
  Config: '开发配置',
  Weclome: '欢迎',
  WeclomeTo: '来到海艺企业后台',
  Search: '搜索',
  Reset: '重置',
  Status: '状态',
  Handle: '操作',
  CreatedTime: '创建时间',
  Disabled: '禁用',
  Normal: '正常',
  Enable: '启用',
  Selected: '已选',
  Page: '页',
  Items: '条',
  Go: '前往',
  Confirm: '确认',
  Cancel: '取消',
  Prompt: '提示',
  Created: '创建',
  Add: '添加',
  HandleSuccess: '操作成功',
  HandleError: '操作失败',
  ExitFullscreen: '退出全屏',
  FullScreen: '全屏窗口',
  ChangePassword: '修改密码',
  SignOut: '退出后台',
  CustomSuccess: '{type}成功',
  CustomError: '{type}失败',
  Day: '天',
  Hours: '小时',
  Minute: '分钟',
  Second: '秒',
  PayPal: '去PayPal支付',
  Payermax: '去Payermax支付',
  Account1: '用户',
  Account2: '请输入用户昵称',
  Account3: '第三方账号',
  Account4: '请输入第三方账号',
  Account5: '创建时间',
  Account6: '请选择开始时间',
  Account7: '请选择结束时间',
  Account8: '批量新增',
  Account9: '批量导出',
  Account10: '用户ID',
  Account11: '用户昵称',
  Account12: '用户账号',
  Account13: '累计消耗GPU(天)',
  Account14: '今日消耗GPU(时)',
  Account15: '累计使用存储',
  Account16: '设为管理',
  Account17: '重置密码',
  Account18: '新增用户',
  Account19: '请输入新增数量：(当前还可免费创建{num}个)',
  Account20: '请输入创建账号数',
  Account21: '创建账号成功',
  Account22: '创建账号失败',
  Account23: '创建免费账号超过限制，请充值！',
  Account24: '去充值',
  Account25: '密码格式不正确',
  Account26: '是否{type}【{name}】的管理权限',
  Account27: '请输入新密码(账号:【{name}】)',
  Account28: '新增数量输入不正确',
  Account29: '请输入创建数量',
  Account30: '每次最多生成500个账号!',
  Account31: '新增子账号后将自动导出子账号，请妥善保存！',
  Account32: '取消管理',
  Account33: '设为管理',
  Account34: '确定{type}账号:【{name}】?',
  Account35: '密码长度为8~20个字符',
  Account36: '重置失败，请重试',
  Analysis1: '使用量统计',
  Analysis3: 'GPU累计使用时长',
  Analysis4: '含存储/账号换算的累计GPU消耗',
  Analysis5: '累计使用存储',
  Analysis7: '剩余量统计',
  Analysis8: 'GPU剩余使用时长',
  Analysis9: '企业版海艺登录和API调用累计总剩余量',
  Analysis11: '选择查询月份',
  Analysis12: '日均使用时长：',
  Analysis13: '日均使用存储：',
  Analysis14: '每日使用',
  Analysis16: '时长',
  Analysis17: '存储',
  Analysis18: '日',
  API1: '文档正在更新中, 查看地址请点击',
  API2: '这里',
  Renewal1: 'GPU时长续费',
  Renewal2: '客户等级',
  Renewal3: '赠送账号',
  Renewal4: '赠送存储',
  Renewal5: '3个月试用',
  Renewal6: '收费标准',
  Renewal7: '元/小时',
  Renewal77: '美元/小时',
  Renewal8: '购买GPU小时',
  Renewal9: '张',
  Renewal10: '赠送GPU小时',
  Renewal11: '小时',
  Renewal12: '续费套餐常见问题',
  Renewal13: '计算中',
  Renewal15: '立即购买',
  Renewal16: 'GPU{level}套餐{num}元/小时',
  Renewal166: 'GPU{level}套餐{num}美元/小时',
  Renewal17: '立即购买',
  Renewal18: '续费常见问题',
  Renewal19: '是否支持退款？',
  Renewal20: '不支持退款',
  Renewal21: '如果赠送存储试用期过了，如何继续购买存储？',
  Renewal22: '无需单独购买，我们将根据您使用的存储空间，换算并扣取对应的GPU时长，每天结算扣取一次。',
  Renewal23: '如果账号数量不足，如何继续增购账号数量？',
  Renewal24: '无需单独增购，我们会根据您使用的账号数量，换算并扣取对应的GPU时长，每天结算扣取一次。',
  Renewal25: '我的GPU时长消耗扣费规则是什么？',
  Renewal26: "GPU\u65F6\u957F\u7684\u6D88\u8D39\u6263\u9664\u9879\u76EE\u5305\u542B\u4E09\u90E8\u5206\u5185\u5BB9\uFF1A <br>\n  (\uFF11) \u751F\u6210\u56FE\u7247\u6263\u8D39\u89C4\u5219\uFF1A\u6839\u636E\u6BCF\u5F20\u56FE\u7247\u751F\u6210\u65F6\u6240\u6D88\u8017\u7684GPU\u65F6\u95F4\u6263\u9664\u5BF9\u5E94\u4E3B\u8D26\u53F7\u4E0B\u7684\u65F6\u957F\uFF0C\u5B9E\u65F6\u6263\u8D39\u3002<br>\n  (\uFF12) \u5B58\u50A8\u6263\u8D39\u89C4\u5219\uFF1A\u5B58\u50A8\u91C7\u7528\u6309\u5929\u6536\u8D39\uFF0C1G\u5B58\u50A8\u7B49\u4E8E5\u79D2GPU\u6BCF\u5929\u3002\u6BCF\u592900:00\u70B9\u7ED3\u7B97\u6263\u53D6\u4E00\u6B21\uFF0C\u8D85\u8FC7\u4F46\u4E0D\u6EE11G\uFF0C\u6309\u71671G\u6263\u8D39\u3002<br>\n  (\uFF13) \u8D26\u53F7\u6263\u8D39\u89C4\u5219\uFF1A\u8D26\u53F7\u91C7\u7528\u4E00\u6B21\u6027\u6536\u8D39\uFF0C\u5F53\u8D85\u8FC7\u8D60\u9001\u7684\u8D26\u53F7\u6570\u91CF\u65F6\uFF0C\u989D\u5916\u65B0\u589E\u7684\u8D26\u53F7\uFF0C1\u4E2A\u7B49\u4E8E1\u5C0F\u65F640\u5206GPU\u65F6\u957F\uFF0C\u6BCF\u5929\u6E05\u7B97\u65F6\u4E00\u6B21\u6027\u6263\u96641\u8D26\u53F7\u5BF9\u5E94\u65F6\u957F\u3002",
  Renewal27: '不同档位的套餐可以叠加吗？',
  Renewal28: "\u53EF\u4EE5\u53E0\u52A0\u3002\u53E0\u52A0\u89C4\u5219\u4E3A\uFF1A<br>\n  (\uFF11) GPU\u65F6\u957F\uFF1A\u4F1A\u6839\u636E\u8D2D\u4E70\u91D1\u989D\u8FDB\u884C\u53E0\u52A0\u3002<br>\n  (\uFF12) \u4F01\u4E1A\u8D26\u53F7\u6570\u91CF\uFF1A\u540C\u6863\u4F4D\u5957\u9910\u8D2D\u4E70\u65F6\uFF0C\u8D60\u9001\u6570\u91CF\u4E0D\u8FDB\u884C\u53E0\u52A0\uFF0C\u5347\u7EA7\u5957\u9910\u6863\u4F4D\u65F6\uFF0C\u6309\u7167\u7528\u6237\u5F53\u524D\u6240\u5C5E\u5957\u9910\u6863\u4F4D\u8FDB\u884C\u5DEE\u989D\u8D60\u9001\uFF0C\u8D2D\u4E70\u540E\u7ACB\u5373\u751F\u6548\u3002<br>\n  (\uFF13) \u8D60\u9001\u5B58\u50A8\uFF1A\u540C\u6863\u4F4D\u5957\u9910\u8D2D\u4E70\u65F6\uFF0C\u8D60\u9001\u5B58\u50A8\u4E0D\u8FDB\u884C\u53E0\u52A0\uFF0C\u5347\u7EA7\u5957\u9910\u6863\u4F4D\u65F6\uFF0C\u6309\u7167\u7528\u6237\u5F53\u524D\u6240\u5C5E\u5957\u9910\u6863\u4F4D\u8FDB\u884C\u5DEE\u989D\u8D60\u9001\uFF0C\u8D2D\u4E70\u540E\u7ACB\u5373\u751F\u6548\u3002",
  Renewal29: '如何升级套餐档位？',
  Renewal30: "\u6839\u636E\u8D26\u6237\u5386\u53F2\u8D2D\u4E70\u91D1\u989D\u81EA\u52A8\u8FDB\u884C\u5957\u9910\u5347\u7EA7:<br>\n  (\uFF11) \u5386\u53F2\u8D2D\u4E70\u91D1\u989D\u572810W\u4EE5\u5185\u4EAB\u53D7\u57FA\u7840\u7248\u5957\u9910\u6743\u76CA\uFF0C\u8D60\u9001\u6C38\u4E45\u4F01\u4E1A\u6570\u91CF50\u4E2A\uFF0C3\u4E2A\u6708\u8BD5\u7528\u5B58\u50A880G<br>\n  (\uFF12) \u5386\u53F2\u8D2D\u4E70\u91D1\u989D\u572810W-30W\u4EAB\u53D7\u521D\u7EA7\u7248\u5957\u9910\u6743\u76CA\uFF0C\u8D60\u9001\u6C38\u4E45\u4F01\u4E1A\u6570\u91CF100\u4E2A\uFF0C3\u4E2A\u6708\u8BD5\u7528\u5B58\u50A8500G<br>\n  (\uFF13) \u5386\u53F2\u8D2D\u4E70\u91D1\u989D\u572830W\u4EE5\u4E0A\u4EAB\u53D7\u4E13\u4E1A\u7248\u5957\u9910\u6743\u76CA\uFF0C\u8D60\u9001\u6C38\u4E45\u4F01\u4E1A\u6570\u91CF500\u4E2A\uFF0C3\u4E2A\u6708\u8BD5\u7528\u5B58\u50A81T",
  Renewal31: '支付订单：',
  Renewal32: '购买详情',
  Renewal33: '实付金额',
  Renewal34: '选择支付方式：',
  Renewal35: '去支付宝支付',
  Renewal36: '微信支付',
  Renewal37: '生成二维码',
  Renewal38: '支付请求处理中，请稍后...',
  Renewal39: '支付成功',
  Renewal40: 'GPU套餐包',
  Renewal41: '订单编号',
  Renewal42: '付款时间',
  Renewal43: '完成',
  Renewal44: '赠',
  Purchase1: '购买历史',
  Purchase2: '订单编号',
  Purchase3: '购买套餐',
  Purchase4: '订单金额',
  Purchase5: '账号数量',
  Purchase6: 'GPU时长',
  Purchase7: '存储空间',
  Purchase8: '购买日期',
  Purchase9: '规则详情',
  Purchase10: '基础版',
  Purchase11: '专业版',
  Purchase12: '高级版',
  Purchase13: '客户等级',
  Purchase14: '服务项目',
  Purchase15: '服务计价方式',
  Purchase16: '服务规则',
  Purchase17: '赠送福利',
  Purchase18: '同等级内赠送福利不会叠加，升级等级后会进行差额赠送',
  Purchase19: '费用标准',
  Purchase20: '请选择维度',
  Purchase21: 'GPU运算时长统计维度',
  Purchase22: '图片统计维度',
  Purchase23: '个'
};