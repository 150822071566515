var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "config-page"
  }, [_c("div", {
    staticClass: "client-id"
  }, [_vm._v("client_id: " + _vm._s(_vm.clientId))]), _vm._v(" "), _c("div", {
    staticClass: "secret"
  }, [_vm._v("\n    secret: " + _vm._s(_vm.isShow ? _vm.secret : "******") + "\n    "), _c("i", {
    staticClass: "icon haiyi",
    class: _vm.isShow ? "haiyi-yanjing_xianshi" : "haiyi-yanjing_yincang",
    on: {
      click: function click($event) {
        _vm.isShow = !_vm.isShow;
      }
    }
  })])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };