import { getConfigInfo } from "@/api/devConfig";
export default {
  data: function data() {
    return {
      isShow: false,
      clientId: "",
      secret: ""
    };
  },
  created: function created() {
    this.getInfo();
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;
      getConfigInfo().then(function (res) {
        var _res$data = res.data,
          client_id = _res$data.client_id,
          secret = _res$data.secret;
        _this.clientId = client_id;
        _this.secret = secret;
      });
    }
  }
};