function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import TableFilter from "@/components/TableFilter";
import CTable from "@/components/CTable";
import { getAccountList, updateAccountStatus, updateAccountRole, resetPasw, batchAddAccount } from "@/api/account";
import { timestampToTime } from "@/utils/diffDate";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    TableFilter: TableFilter,
    CTable: CTable
  },
  data: function data() {
    var _this = this;
    var h = this.$createElement;
    return {
      config: {
        multiple: true,
        getDataFun: getAccountList,
        columns: [{
          label: "Account10",
          key: "third_id"
        }, {
          label: "Account11",
          key: "name",
          render: function render(row) {
            return h("p", [row.name || "-", " "]);
          }
        }, {
          label: "Account12",
          key: "id",
          render: function render(row) {
            return h("p", [row.email || row.id]);
          }
        }, {
          label: "Status",
          key: "status",
          render: function render(row) {
            return h("p", [row.status === 1 ? _this.$t("Normal") : _this.$t("Disabled"), " "]);
          }
        }, {
          label: "Account13",
          width: 150,
          key: "total_usage",
          render: function render(row) {
            var total_usage = row.total_usage;
            total_usage = total_usage / (24 * 3600000);
            return h("p", [parseInt(total_usage), "\u5929"]);
          }
        }, {
          label: "Account14",
          width: 150,
          key: "day_usage",
          render: function render(row) {
            var day_usage = row.day_usage;
            day_usage = day_usage / 3600000;
            return h("p", [Math.floor(day_usage * 10) / 10, "\u5C0F\u65F6"]);
          }
        }, {
          label: "Account15",
          width: 150,
          key: "total_storage_used",
          render: function render(row) {
            var total_storage_used = row.total_storage_used;
            total_storage_used = total_storage_used / 1000000;
            return h("p", [Math.floor(total_storage_used * 10) / 10, "MB"]);
          }
        }, {
          label: "CreatedTime",
          key: "create_at",
          width: 200,
          render: function render(row) {
            var time = timestampToTime(row.create_at);
            return h("p", [time, " "]);
          }
        }, {
          label: "Handle",
          key: "handle",
          width: 250,
          align: "center",
          fixed: "right",
          render: function render(row) {
            var status = row.status,
              role = row.role;
            var isAdmin = ["root", "admin"].includes(role);
            return h("div", {
              "class": "handle-btn-group"
            }, [h("el-button", {
              "attrs": {
                "type": "primary",
                "plain": true,
                "size": "mini"
              },
              "class": "pass-btn handle-btn",
              "on": {
                "click": function click() {
                  _this.handleEvent(row, "DISABLED");
                }
              }
            }, [status === 1 ? _this.$t("Disabled") : _this.$t("Enable")]), h("el-button", {
              "attrs": {
                "type": "primary",
                "plain": true,
                "size": "mini"
              },
              "class": "pass-btn handle-btn",
              "on": {
                "click": function click() {
                  _this.handleEvent(row, "SET_ADMIN");
                }
              }
            }, [isAdmin ? _this.$t("Account32") : _this.$t("Account33")]), h("el-button", {
              "attrs": {
                "type": "primary",
                "plain": true,
                "size": "mini"
              },
              "class": "pass-btn handle-btn",
              "on": {
                "click": function click() {
                  _this.handleEvent(row, "RESET");
                }
              }
            }, [_this.$t("Account17")])]);
          }
        }]
      },
      filterConfig: [{
        label: "Account1",
        type: "input",
        placeholder: "Account2",
        key: "name"
      }, {
        label: "Account3",
        type: "input",
        placeholder: "Account4",
        key: "third_name"
      }, {
        label: "CreatedTime",
        type: "datetimerange",
        placeholder: ["Account6", "Account7"],
        key: "time_from,time_to"
      }],
      banUserInfo: []
    };
  },
  computed: _objectSpread({}, mapState("user", ["companyInfo"])),
  methods: _objectSpread(_objectSpread({}, mapActions("user", ["setCompanyInfo"])), {}, {
    search: function search(param) {
      this.$refs.customTable.initTable(param);
    },
    handleEvent: function handleEvent(row, status) {
      this.banUserInfo = row instanceof Array ? row : [row];
      switch (status) {
        case "SET_ADMIN":
          this.setAdmin(row);
          break;
        case "DISABLED":
          this.disabledUser(row);
          break;
        case "ADD_USER":
          this.addUser(row);
          break;
        case "RESET":
          this.resetPasw(row);
          break;
        default:
          this.batchExport(row);
      }
    },
    batchExport: function batchExport(row) {
      var _this2 = this;
      var txt = "Account10,Account11,Account12,Status,Account13,Account14,Account15,CreatedTime";
      txt = this.getLangString(txt);
      txt += row.reduce(function (txt, item) {
        var id = item.id,
          name = item.name,
          third_id = item.third_id,
          email = item.email,
          status = item.status,
          total_usage = item.total_usage,
          total_storage_used = item.total_storage_used,
          day_usage = item.day_usage,
          create_at = item.create_at;
        return txt + "\n".concat(id, ",").concat(name, ",").concat(email || third_id, ",").concat(status === 1 ? _this2.$t("Normal") : _this2.$t("Disabled"), ",").concat(total_usage, ",").concat(day_usage, ",").concat(total_storage_used, ",").concat(timestampToTime(create_at));
      }, "");
      this.createFile(txt, this.$t("Account9") + ".csv");
    },
    getLangString: function getLangString(text) {
      var _this3 = this;
      var texts = text.split(",");
      return texts.map(function (key) {
        return _this3.$t(key);
      }).toString();
    },
    setAdmin: function setAdmin(row) {
      var _this4 = this;
      var id = row.id,
        name = row.name,
        role = row.role;
      var isAdmin = ["root", "admin"].includes(role);
      var type = isAdmin ? this.$t("Cancel") : this.$t("Add");
      this.$confirm(this.$t("Account26", {
        type: type,
        name: name || id
      }), this.$t("Prompt"), {
        type: "info",
        customClass: "custom-confirm",
        cancelButtonClass: "cancel-btn"
      }).then(function () {
        _this4.setAdminReq({
          id: id,
          role: isAdmin ? "normal" : "admin"
        });
      });
    },
    setAdminReq: function setAdminReq(params) {
      var _this5 = this;
      updateAccountRole(params).then(function (res) {
        _this5.$message.success(_this5.$t("HandleSuccess"));
        _this5.refreshTable();
      }).catch(function (err) {
        _this5.$message.error(_this5.$t("HandleError"));
      });
    },
    disabledUser: function disabledUser(row) {
      var _this6 = this;
      var status = row.status,
        id = row.id,
        name = row.name;
      var type = status === 1 ? this.$t("Disabled") : this.$t("Enable");
      this.$confirm(this.$t("Account34", {
        type: type,
        name: name || id
      }), this.$t("Prompt"), {
        cancelButtonClass: "cancel-btn",
        type: "info",
        customClass: "custom-confirm"
      }).then(function () {
        _this6.submitBan({
          id: id,
          status: status === 1 ? 2 : 1
        });
      });
    },
    submitBan: function submitBan(params) {
      var _this7 = this;
      var type = params.status === 1 ? this.$t("Enable") : this.$t("Disabled");
      updateAccountStatus(params).then(function (res) {
        _this7.$message.success(_this7.$t("CustomSuccess", {
          type: type
        }));
        _this7.refreshTable();
      }).catch(function (err) {
        _this7.$message.error(_this7.$t("CustomError", {
          type: type
        }));
      });
    },
    addUser: function addUser() {
      var _this8 = this;
      var left_emp = this.companyInfo.left_emp;
      console.log(this.companyInfo);
      this.$prompt(this.$t("Account19", {
        num: left_emp
      }), this.$t("Account18"), {
        customClass: "custorm-messagebox",
        cancelButtonClass: "cancel-btn",
        confirmButtonClass: "confirm-btn",
        inputErrorMessage: this.$t("Account28"),
        inputPlaceholder: this.$t("Account20"),
        inputValidator: function inputValidator(val) {
          var regex = /^[0-9]+$/;
          if (!val) {
            return _this8.$t("Account29");
          }
          if (val && val > 500) {
            return _this8.$t("Account30");
          }
          // if (val && val > left_emp) {
          //   return `最多可以创建${left_emp}个账号！`;
          // }
          return val && regex.test(val);
        }
      }).then(function (_ref) {
        var value = _ref.value;
        _this8.$confirm(_this8.$t("Account31"), _this8.$t("Prompt"), {
          cancelButtonClass: "cancel-btn",
          type: "info",
          customClass: "custom-confirm"
        }).then(function () {
          _this8.batchAddUser(parseInt(value));
        });
      }).catch(function () {});
    },
    batchAddUser: function batchAddUser(num) {
      var _this9 = this;
      var left_emp = this.companyInfo.left_emp;
      batchAddAccount({
        num: num
      }).then(function (res) {
        var items = res.data.items;
        _this9.setCompanyInfo(_objectSpread(_objectSpread({}, _this9.companyInfo), {}, {
          left_emp: left_emp - num
        }));
        _this9.$message.success(_this9.$t("Account21"));
        var text = _this9.getLangString("Account,Password");
        text += items.reduce(function (txt, item) {
          return "".concat(txt, "\n").concat(item.email || item.id, ",").concat(item.psd);
        }, "");
        _this9.createFile(text, _this9.$t("Account8") + ".csv");
        _this9.refreshTable();
      }).catch(function (err) {
        var code = err.code;
        if (code === 500600) {
          _this9.showRechargePopup();
        } else {
          _this9.$message.error(_this9.$t("Account22") + "：" + err.msg);
        }
      });
    },
    showRechargePopup: function showRechargePopup() {
      var _this10 = this;
      this.$confirm(this.$t("Account23"), this.$t("Prompt"), {
        confirmButtonText: this.$t("Account24"),
        cancelButtonClass: "cancel-btn",
        type: "info",
        customClass: "custom-confirm"
      }).then(function () {
        _this10.$router.push("/renewalPackage");
      });
    },
    resetPasw: function resetPasw(row) {
      var _this11 = this;
      var name = row.name,
        id = row.id;
      this.$prompt(this.$t("Account27", {
        name: name || id
      }), this.$t("Account17"), {
        customClass: "custorm-messagebox",
        cancelButtonClass: "cancel-btn",
        confirmButtonClass: "confirm-btn",
        inputErrorMessage: this.$t("Account25"),
        inputValidator: function inputValidator(val) {
          var regex = /^[0-9]+$/;
          if (val && (val.length < 8 || val.length > 20)) {
            return _this11.$t("Account35");
          }
          return val && regex.test(val);
        }
      }).then(function (_ref2) {
        var value = _ref2.value;
        _this11.resetPaswReq({
          user_id: id,
          new_psd: value
        });
      }).catch(function () {});
    },
    resetPaswReq: function resetPaswReq(params) {
      var _this12 = this;
      resetPasw(params).then(function (res) {
        _this12.$message.success(_this12.$t("CustomSuccess", {
          type: _this12.$t("Account17")
        }));
      }).catch(function (err) {
        _this12.$message.error(_this12.$t("Account36"));
      });
    },
    refreshTable: function refreshTable() {
      this.$refs.customTable.getData();
    },
    createFile: function createFile(txt, fileName) {
      var blob = new Blob(["\uFEFF" + txt], {
        type: "text/csv;charset=UTF-8;"
      }); // 创建一个 Blob 对象
      var url = URL.createObjectURL(blob); // 创建一个 URL 对象

      var link = document.createElement("a"); // 创建一个 <a> 元素
      link.setAttribute("href", url);
      link.setAttribute("download", fileName); // 设置下载的文件名
      link.style.display = "none";
      document.body.appendChild(link); // 将 <a> 元素添加到文档中
      link.click(); // 触发下载操作
      document.body.removeChild(link); // 下载完成后，将 <a> 元素从文档中移除
    }
  })
};